/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {graphql, Link, useStaticQuery} from "gatsby";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faFacebook,
    faInstagram,
    faLinkedin,
    faLinkedinIn,
    faTwitter,
    faYoutube
} from "@fortawesome/free-brands-svg-icons";
import {faPhone} from "@fortawesome/free-solid-svg-icons/faPhone";

const Footer = () => {
    const data = useStaticQuery(graphql`
       query {
            site{
                siteMetadata {
                    author,
                    phone,
                    email
                }
            }
        }
   `)

    return (
        <footer className="footer mt-auto text-white pt-5">
            {/*<p>Kutnohorak.cz - Dávka informací z dění v Kutné Hoře</p>*/}
        </footer>
    );
}

export default Footer
