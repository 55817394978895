import {Helmet} from "react-helmet";
import React from "react";
import PropTypes from "prop-types";
import {withPrefix} from "gatsby"

const SEO = ({title}) => {
    return (
        <Helmet
            htmlAttributes={{
                lang: 'cs',
            }}
            title={title}
        >
            <body className="d-flex flex-column h-100"/>
        </Helmet>
    )
}

SEO.propTypes =
    {
        title: PropTypes.string.isRequired,
    }

export default SEO