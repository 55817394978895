import React from "react";
import {StaticImage} from "gatsby-plugin-image";



export default function Logo() {
    return (
        <div className={"logo"}>
            <StaticImage className={"logo-img"} src={"../../assets/custom-style/images/logo-veze.webp"} alt={"Kutnohorák"} />
            <br />
            <span className={"logo-motto"}> Kutnohorák</span>
        </div>
    );
}
